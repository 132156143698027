module.exports = {
  model: {
    id: '',
    company_name: '',
    cnpj: '',
    street: '',
    city: '',
    email: '',
  },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercase: true },
        label: 'Nome da empresa',
        model: 'company_name',
        placeholder: 'Informe o nome da empresa',
        validator: ['required'],
        required: true,
      },
      {
        type: 'vfg-the-mask',
        label: 'CNPJ',
        model: 'cnpj',
        placeholder: 'Ex: 00.000.000/0000-00',
        required: true,
        validator: ['required'],
        mask: '##.###.###/####.##',
      },
      {
        type: 'custom-input',
        options: { lowercase: true },
        label: 'E-mail',
        model: 'email',
        placeholder: 'Informe um e-mail',
      },
      {
        type: 'custom-input',
        options: { uppercase: true },
        label: 'Endereço',
        model: 'street',
        placeholder: 'Informe o endereço',
        validator: ['required'],
        required: true,
      },
      {
        type: 'vueMultiSelect',
        label: 'Estado',
        model: 'state',
        placeholder: 'Selecione um estado',
        multiSelect: false,
        selectOptions: {
          multiple: false,
          trackBy: 'id',
          label: 'name',
          selectLabel: '',
          searchable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
      {
        type: 'vueMultiSelect',
        label: 'Cidade',
        model: 'city',
        placeholder: 'Selecione uma cidade',
        multiSelect: false,
        selectOptions: {
          multiple: false,
          trackBy: 'name',
          label: 'name',
          selectLabel: '',
          searchable: true,
          closeOnSelect: true,
          hideSelected: true,
        },
        values: [],
      },
    ],
  },
  columns: [
    { key: 'company_name', label: 'nome da empresa', filter: true },
    {
      key: 'cnpj',
      label: 'cnpj',
      mask: '##.###.###/####-##',
      filter: true,
    },
    { key: 'street', label: 'endereço' },
    { key: 'state', label: 'estado' },
    { key: 'city', label: 'cidade' },
    { key: 'email', label: 'e-mail' },
  ],
  viewDataTypes: [
    { key: 'company_name', label: 'Nome' },
    { key: 'cnpj', label: 'CNPJ', mask: '##.###.###/####-##' },
    { key: 'street', label: 'Rua' },
    { key: 'state', label: 'Estado' },
    { key: 'city', label: 'Cidade' },
    { key: 'email', label: 'E-mail' },
  ],
}
