<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo fornecedor</span>
    </button>
    <b-table
      :data="allSuppliers"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="
        (row) =>
          $buefy.toast.open(`Visualizando os detalhes de ${row.company_name}`)
      "
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="number"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.company_name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{
            props.row.company_name
          }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="cnpj"
        label="CNPJ"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.cnpj | VMask('##.###.###/####-##') }}</b-table-column
      >

      <b-table-column
        field="street"
        label="Endereço"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.street }}</b-table-column
      >

      <b-table-column
        field="email"
        label="E-mail"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.email ? props.row.email : 'N/A' }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            edit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.company_name }}</strong>
                <br />
                <strong>CNPJ:</strong>
                <small>{{
                  props.row.cnpj | VMask('##.###.###/####-##')
                }}</small>
                <br />
                <span v-if="props.row.email">
                  <strong>E-mail:</strong>
                  <small>{{ props.row.email }}</small>
                  <br />
                </span>
                <span v-if="props.row.phone_number">
                  <strong>Telefone:</strong>
                  <small>{{
                    props.row.phone_number | VMask('(##)#####-####')
                  }}</small>
                  <br />
                </span>
                <strong>Endereço:</strong>
                <small>{{ props.row.street }}</small>
                <br />
                <strong>Cidade:</strong>
                <small>{{ props.row.city }}</small>
                <br />
                <strong>Estado:</strong>
                <small>{{ props.row.state }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome da empresa *">
              <b-input v-model="model.company_name" required></b-input>
            </b-field>
            <b-field label="CNPJ *">
              <b-input
                v-model="model.cnpj"
                v-cleave="masks.cnpj"
                required
              ></b-input>
            </b-field>
            <b-field label="Telefone">
              <b-input
                v-model="model.phone_number"
                v-cleave="masks.phoneNumber"
              ></b-input>
            </b-field>
            <b-field label="E-mail">
              <b-input v-model="model.email" type="email"></b-input>
            </b-field>
            <b-field label="Endereço *">
              <b-input v-model="model.street" required></b-input>
            </b-field>
            <b-field label="Estado *">
              <b-select
                @input="statesCities"
                placeholder="Selecione um estado"
                required
                v-model="model.state"
              >
                <option
                  v-for="(item, index) in states"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Cidade *">
              <b-select
                placeholder="Selecione uma cidade"
                required
                v-model="model.city"
              >
                <option
                  v-for="(item, index) in cities"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '../event-bus.js'
import service from '../features/suppliers/store/service'
import Supplier from '../models/supplier'
import StatesCities from '../assets/data/estados-cidades.json'

import Cleave from 'cleave.js'

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  },
}

export default {
  name: 'Suppliers',
  directives: { cleave },
  data() {
    return {
      viewDataTypes: Supplier.viewDataTypes,
      columns: Supplier.columns,
      model: Supplier.model,
      schema: Supplier.schema,
      viewData: {},
      cities: [],
      states: [],
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
      masks: {
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          numericOnly: true,
        },
        phoneNumber: {
          delimiters: ['(', ')', '-'],
          blocks: [0, 2, 5, 4],
          numericOnly: true,
        },
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('suppliers', ['allSuppliers']),
  },
  methods: {
    ...mapActions('suppliers', ['getAllSuppliers']),
    async init() {
      await this.getAllSuppliers()

      this.states = StatesCities.states.map((x) => {
        return { id: x.initials, name: x.name }
      })

      this.cities = StatesCities.states[0].cities
      this.model.city = StatesCities.states[0].cities[0]
      this.model.state = this.states[0].id

      console.log(this.model)
    },
    statesCities() {
      this.cities = StatesCities.states.find(
        (x) => x.initials === this.model.state
      ).cities
    },
    async save() {
      const supplier = { ...this.model }

      if (!this.$validateCnpj(supplier.cnpj)) {
        return this.$error('O CNPJ informado é inválido')
      }

      supplier.cnpj = supplier.cnpj
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '')

      supplier.phone_number = supplier.phone_number
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '')

      console.log(supplier)

      try {
        const save = supplier.id
          ? await service.updateSupplier(supplier)
          : await service.saveSupplier(supplier)

        await this.$success('Fornecedor')
        await location.reload(true)

        this.model = Supplier.model
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o fornecedor?')

        if (result.isConfirmed) {
          await service.deleteSupplier(id)

          await this.$delete('Fornecedor')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    edit(item) {
      console.log(item)
      this.model = { ...item }
      this.$modal.show('view')
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
  },
}
</script>

<style scoped></style>
